// CSS Style
import 'styles/client/vendors/animate.min';
import 'styles/trialsite';
import Rails from "@rails/ujs"

// Other JS
import WOW from 'wow.js';
import 'client/base';

Rails.start();
// 因為在 webpack 打包的時候，沒有瀏覽器的 window 物件，所以必須 assign 這樣才能在 rails view 使用
// Ref: https://tinyurl.com/ycrtavmd
window.jQuery = $;
window.$ = $;

$(document).ready(function () {
  var wow = new WOW({
    boxClass: 'wow',
    animateClass: 'animated',
    offset: 0,
    mobile: false,
    live: false,
    scrollContainer: null
  });
  wow.init();

  var wow_all = new WOW({
    boxClass: 'wow_all',
    animateClass: 'animated',
    offset: 0,
    mobile: true,
    live: false,
    scrollContainer: null
  });
  wow_all.init();

});
